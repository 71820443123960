import React from 'react'
import Styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Container = Styled.div`
    position: fixed;
    top: 5px;
    right: 5px;
    z-index: 5;

    @media screen and (min-width: 720px) {
        position: absolute;
        left: 0;
        top: 55px;
        right: 0;
        height: 50px;
    }

    @media screen and (min-width: 900px) {
        left: 460px;
        top: 10px;
        height: 40px;
    }
`
const MenuButton = Styled.button`
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    & > div {
        position: absolute;
        height: 10px;
        width: 50px;
        left: 0;
        background: #7BC3DA;
        transition: all .25s ease;

        &:nth-child(1) {
            top: 10px;
        }
        &:nth-child(2) {
            top: 30px;
        }
    }

    &.active {
        & > div:nth-child(1) {
            top: 20px;
            transform: rotate(45deg);
        }
        & > div:nth-child(2) {
            top: 20px;
            transform: rotate(-45deg);
        }
    }

    @media screen and (min-width: 720px) {
        display: none;
    }
`
const Nav = Styled.nav`
    position: fixed;
    top: 0;
    right: -90vw;
    height: 100vh;
    width: 80vw;
    padding: 15px;
    background: #f2f2f2;
    box-sizing: border-box;
    overflow-y: auto;
    transition: right .25s ease;

    &.active {
        right: 0;
    }

    @media screen and (min-width: 720px) {
        position: static;
        width: 100%;
        height: 50px;
        padding: 0;
        background: none;
    }
`
const NavBackground = Styled.div`
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.75);

    &.active {
        display: block;
    }
`
const MenuTitle = Styled.h2`
    display: block;
    color: #4F4F4F;
    font-size: 16px;
    font-weight: normal;
    margin-right: 60px;
    min-height: 55px;
    text-align: center;
    
    @media screen and (min-width: 720px) {
        display: none;
    }
`
const MenuList = Styled.ul`
    list-style-type: none;

    @media screen and (min-width: 720px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`
const MenuItem = Styled.li`
    margin: 10px;

    @media screen and (min-width: 720px) {
        margin: 0;
    }
`
const Link = Styled(AniLink)`
    display: block;
    padding: 15px;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    color: #BDBDBD;
    transition: all .25s ease;

    &:hover, &:focus {
        color: #7BC3DA;
    }

    &.active {
        color: #000;
        cursor: default;
    }

    @media screen and (min-width: 720px) {
        padding: 10px;
        font-size: 18px;
    }
`

class Menu extends React.Component {
    state = {
        active: false
    }

    openMenu = () => {
        this.setState({ active: true })
    }

    closeMenu = () => {
        this.setState({ active: false })
    }

    toggleMenu = () => {
        if (this.state.active) {
            this.closeMenu()
        } else {
            this.openMenu()
        }
    }

    componentDidUpdate() {
        if (this.state.active) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }

    render() {
        const { closeMenu, toggleMenu } = this
        const { active } = this.state
        return (
            <Container>
                <NavBackground className={(active)?'active':null} onClick={closeMenu} />
                <Nav className={(active)?'active':null}>
                    <MenuTitle>Dr. Kimberly Ludwigsen, PhD, MFT, LCSW, CST</MenuTitle>
                    <MenuList>
                        <MenuItem>
                            <Link cover direction='right' bg='#7BC3DA' activeClassName='active' to='/'>Home</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link cover direction='left' bg='#7BC3DA' activeClassName='active' to='/aboutKim'>About Kim</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link cover direction='left' bg='#7BC3DA' activeClassName='active' to='/sexTherapy'>Sex Therapy</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link cover direction='left' bg='#7BC3DA' activeClassName='active' to='/resources'>Resources</Link>
                        </MenuItem>
                    </MenuList>
                </Nav>
                <MenuButton onClick={toggleMenu} className={(active)?'active':null}>
                    <div />
                    <div />
                </MenuButton>
            </Container>
        )
    }
}

export default Menu

import React from 'react'
import Styled from 'styled-components'

const Container = Styled.section`
    max-width: 900px;
    margin: auto;
    background: #fff;
    min-height: 100vh;
`

const Body = ({ children }) => (
    <Container role='main'>
        {children}
    </Container>
)

export default Body

import React from 'react'
import Styled from 'styled-components'

const Container = Styled.footer`
    background: #4F4F4F;
    padding: 10px;
    font-family: 'Roboto Condensed';
    text-align: center;
`
const Title = Styled.h4`
    color: #BDBDBD;
    font-size: 20px;
    font-weight: normal;
`
const Info = Styled.p`
    color: #828282;
    font-size: 18px;
`
const BottomSection = Styled.div`
    margin-top: 30px;
`
const Verification = Styled.div`

    & > img {
        box-shadow: 0 0 3px 1px rgba(0,0,0,0.5);
    }
`
const Copyright = Styled.p`
    color: #828282;    
`

const Footer = () => (
    <Container id='footer'>
        <Title>Dr. Kimberly Ludwigsen, PhD, MFT, LCSW, CST</Title>
        <br />
        <Info>1 Sheppard Road, Suite 703</Info>
        <Info>Voorhees, NJ, 08043</Info>
        <Info>(856) 448-4352</Info>
        <BottomSection>
            <Verification>
                <img src='https://www.psychologytoday.com/profile/95363/verification.gif' alt='Physchology Today verification.' />
            </Verification>
            <Copyright>
                Copyright {new Date().getFullYear()}
            </Copyright>
        </BottomSection>
    </Container>
)

export default Footer

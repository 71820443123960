import React from 'react'
import Styled from 'styled-components'
import './wrapper.css'

if (typeof window !== 'undefined') {
    require('smooth-scroll')('a[href*="#"]')
}

const Container = Styled.div`

`

const Wrapper = ({ children }) => (
    <Container>
        {children}
    </Container>
)

export default Wrapper

import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Menu from './menu'

const Container = Styled.header`
    height: 60px;

    @media screen and (min-width: 720px) {
        height: 100px;
    }

    @media screen and (min-width: 900px) {
        height: 60px;
    }
`
const ActualHeader = Styled.div`
    height: 50px;
    padding: 5px;
    box-shadow: 0 2px 3px 1px rgba(0,0,0,0.25);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 5;
    display: grid;
    grid-template-columns: 50px 1fr 50px;

    @media screen and (min-width: 720px) {
        position: absolute;
        height: 90px;
    }

    @media screen and (min-width: 900px) {
        height: 50px;
        grid-template-columns: 50px 400px 1fr;
    }
`
const Logo = Styled(AniLink)`
    display: inline-block;
`
const LogoImg = Styled(Img)`
    width: 50px;
    height: 50px;
`
const Titles = Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 720px) {
        flex-direction: row;
    }
`
const Title = Styled.h1`
    color: #828282;
    font-weight: normal;
    font-size: 20px;

    @media screen and (max-width: 315px) {
        font-size: 16px;
    }
`
const Subtitles = Styled.p`
    color: #828282;
    font-size: 10px;

    @media screen and (min-width: 720px) {
        font-size: 20px;

        &:before {
            content: ', ';
        }
    }
`

const Header = () => (
    <StaticQuery 
        query={graphql`
            query {
                logo: file(relativePath: { eq: "logo.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 250) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        `}
        render={data => (
            <Container>
                <ActualHeader>
                    <Logo cover direction='right' bg='#7BC3DA' to='/'>
                        <LogoImg fluid={data.logo.childImageSharp.fluid} alt='Kimberly Ludwigsen Logo' />
                    </Logo>
                    <Titles>
                        <Title>Dr. Kimberly Ludwigsen</Title>
                        <Subtitles>PhD, MFT, LCSW, CST</Subtitles>
                    </Titles>
                </ActualHeader>
                <Menu />
            </Container>
        )}
    />
)

// While it would make sense to have <Menu> within the <ActualHeader> div and the styling even leaves a space for it
// there is a bug that occurs during 'gatsby build' which causes the parent styling of <Menu> to overwrite the styling
// of the <Titles> div on initial page loads.
//
// This solution is a workaround until the bug can be properly traced but has no side effects apart from not making
// intuitive sense for how the divs are organized.

export default Header
